<template>
  <div class="page-box page-privacy-clause">
    <div class="bxb-p50 bg-white bxb-con bxb-m50 privacy-con">
      <div class="title text-center ">
        <h6 class="text-size-28 text-bold">服务协议</h6>
      </div>

      <div class="content bxb-m30-top">
        <p>
          重要提示：小葱APP由百色市远兰小额贷款有限责任公司运营，小葱APP依据本协议的规定为用户提供服务，本协议在用户和小葱APP间具有合同上的法律效力。在此特别提醒用户认真阅读、充分理解本协议各条款，特别是其中所涉及的免除、限制小葱APP责任的条款、对用户权利限制条款、争议解决和法律适用等。请用户审慎阅读并选择接受或不接受本协议（未成年人应在监护人陪同下阅读）。除非用户接受本协议所有条款，否则用户无权使用小葱APP于本协议下所提供的服务。用户一经注册或使用小葱APP即视为对本服务条款的理解和接受。
        </p>
        <h6 class="text-main text-bold margin-top-15 text-size-16">
          一、关于“小葱APP”服务协议的确认和接纳
        </h6>
        <p>
          （一）“小葱APP”服务是由小葱APP向用户提供的“小葱APP”软件服务系统。为了保障您的权益，您在自愿注册使用本小葱APP服务前，必须仔细阅读并接受本服务协议所有条款。
        </p>
        <p>
          （二）小葱APP有权在必要时单方修改或变更本服务协议之内容，并将通过小葱APP网站公布最新的服务协议，不另作个別通知。若您于任何修改或变更本服务条款后继续使用本服务，则视为您已阅读、了解并同意接受修改或变更，若您不同意，则您应主动立即停用本服务。
        </p>
        <p>
          （三）如您所属的国家或地区排除本服务条款内容的全部或部分，则您应立即停止使用小葱APP服务。
        </p>

        <h6 class="text-main text-bold margin-top-15 text-size-16">
          二、关于“小葱APP”服务的注册
        </h6>
        <p>
          在使用“小葱APP”服务前，您必须先注册成为“小葱APP”用户，您同意以下事项：
        </p>
        <p>
          （一）“小葱APP”服务仅向符合中华人民共和国法律规定的具有完全民事权利能力和民事行为能力，能够独立承担民事责任的自然人、法人或其它组织提供。其中香港地区小葱APP用户限符合上述法律规定的自然人用户，且已在中国境内银行开设人民币账户。
        </p>

        <p>
          （二）您必须依小葱APP服务注册表的提示提供您本人真实、最新及完整的资料。
        </p>

        <p>
          （三）您有义务维持并立即更新您的“用户资料”，确保其为真实、最新及完整。若您提供任何错误、虚假、过时或不完整的资料，或者小葱APP有合理的理由怀疑资料为错误、虚假、过时或不完整，小葱APP有权暂停或终止您的用户账户，并拒绝您使用本服务的部份或全部功能，小葱APP不承担任何责任，您并同意负担因此所产生的直接或间接的任何支出。
        </p>

        <p>
          （四）若因您未及时更新基本资料，导致“小葱APP”服务无法提供或提供时发生任何错误，小葱APP亦不承担任何责任，所有后果应由您承担。
        </p>

        <p>
          （五）为了适用法律规范，本条款及任何其他的协议、告示或其他关于您使用本服务账户及服务的通知，您同意小葱APP使用电子方式通知您。电子方式包括但不限于以电子邮件方式、或于本网站或者合作网站上公布、或无线通讯装置通知等方式。
        </p>

        <h6 class="text-main text-bold margin-top-15 text-size-16">
          三、关于“小葱APP”服务的使用
        </h6>
        <p>（一）“小葱APP”用户账户、密码及安全性说明：</p>
        <p>
          您了解并同意 ,
          确保密码及账户的机密安全是您的责任。您将对利用该密码及账户所进行的一切行动及言论，负完全的责任，并同意以下事项：
        </p>
        <p>
          1.您不可对其他任何人泄露您的账户或密码，亦不可使用其他任何人的账户或密码。
        </p>
        <p>
          2.您同意如发现有第三人冒用或盗用您的账户及密码，或其他任何未经合法授权的情形，应立即以有效方式通知小葱APP，同意暂停其使用本服务并采取有效的防范措施。小葱APP在接受您的有效通知前，对第三人使用该服务已发生之效力，除非可证明小葱APP故意或重大过失而不知是未经合法授权之使用，否则小葱APP将不承担任何责任。
        </p>
        <p>（二）暂停、拒绝或终止您的使用</p>
        <p>
          1.您同意小葱APP有权基于单方独立判断，包含但不限于小葱APP认为您已经违反本服务条款的规定，将暂停、中断或终止向您提供“小葱APP”服务（或其任何部分），并将“小葱APP”服务内任何“用户资料”加以移除并删除。
        </p>
        <p>
          2.您同意小葱APP在发现异常交易或有疑义或有违法之虞时，不经通知有权先行暂停或终止您的账户、密码，并拒绝您使用“小葱APP”服务之部份或全部功能。
        </p>
        <p>
          3.您同意在必要时，小葱APP无需进行事先通知即得终止提供“小葱APP”服务，并可能立即暂停、关闭或删除您的账户及您账户中所有相关资料及档案。
        </p>

        <h6 class="text-main text-bold margin-top-15 text-size-16">
          四、用户的守法义务及承诺
        </h6>
        <p>
          您承诺绝不为任何非法目的或以任何非法方式使用本服务，也不将本服务用于禁止或限制物品的交易，并承诺遵守中华人民共和国相关法律、法规及一切使用互联网之国际惯例。您若是中华人民共和国以外之使用者，您同意同时遵守您所属国家或地域的法令。
          您同意并保证不得利用本服务从事侵害他人权益或违法之行为，若有违反者应负所有法律责任，包括但不限于：
        </p>

        <p>
          （一）反对宪法所确定的基本原则，危害国家安全、泄漏国家秘密、颠覆国家政权、破坏国家统一的。
        </p>

        <p>
          （二）侵害他人名誉、隐私权、商业秘密、商标权、著作权、专利权、其他智慧财产权及其他权利。
        </p>

        <p>（三） 违反依法律或合约所应负之保密义务。</p>

        <p>（四） 冒用他人名义使用本服务。</p>

        <p>
          （五）
          从事任何不法交易行为，如贩卖枪支、毒品、禁药、盗版软件或其他违禁物。
        </p>

        <p>（六） 提供赌博资讯或以任何方式引诱他人参与赌博。</p>

        <p>（七） 涉嫌洗钱、套现或进行传销活动的。</p>

        <p>（八） 使用无效信用卡号码，或非使用者本人的信用卡号码进行交易。</p>

        <p>（九） 使用他人银行账户或无效银行账户交易。</p>

        <p>
          （十） 从事任何可能含有电脑病毒或是可能侵害本服务系統、资料之行为。
        </p>

        <p>（十一） 其他小葱APP有正当理由认为不适当之行为。</p>

        <h6 class="text-main text-bold margin-top-15 text-size-16">
          五、免责声明
        </h6>
        <p>如发生下述情形，小葱APP不承担任何法律责任：</p>
        <p>（一）依据法律规定或相关政府部门的要求提供您的个人信息；</p>
        <p>（二）由于您的使用不当或其他自身原因而导致任何个人信息的泄露；</p>
        <p>
          （三）任何由于黑客攻击，电脑病毒的侵入，非法内容信息、骚扰信息的屏蔽，政府管制以及其他任何网络、技术、通信线路、信息安全管理措施等原因造成的服务中断、受阻等不能满足您要求的情形；
        </p>
        <p>
          （四）
          因第三方如运营商的通讯线路故障、技术问题、网络、电脑故障、系统不稳定及其他因不可抗力造成的损失的情形；
        </p>
        <p>
          （五）小葱APP服务明文声明，不以明示、默示或以任何形式对小米及其合作公司服务之及时性、安全性、准确性做出担保。
        </p>
        <p>
          （六）在任何情况下，小葱APP均不对任何间接性、后果性、惩罚性、偶然性、特殊性或刑罚性的损害，包括因您使用小葱APP服务而遭受的利润损失，承担责任。尽管本协议中可能含有相悖的规定，我们对您承担的全部责任，无论因何原因或何种行为方式，始终不超过您在注册期内因使用小葱APP服务而支付给小葱APP的费用(如有)。
        </p>
        <p>
          （七）您可以选择使用您已有的第三方帐号关联小葱APP帐号，小葱APP对于因第三方原因对您造成的损失不承担任何责任。
        </p>

        <h6 class="text-main text-bold margin-top-15 text-size-16">
          六、隐私权保护
        </h6>
        <p>
          小葱APP重视对用户隐私的保护，关于您的用户资料和其他特定资料依小葱APP《隐私政策》受到保护与规范，详情请参阅小葱APP《隐私政策》。
        </p>

        <h6 class="text-main text-bold margin-top-15 text-size-16">
          七、知识产权的保护
        </h6>
        <p>
          （一）小葱APP网站上所有内容，包括但不限于著作、图片、档案、资讯、资料、网站架构、网站画面的安排、网页设计，均由小葱APP或其他权利人依法拥有其知识产权，包括但不限于商标权、专利权、著作权、商业秘密等
        </p>

        <p>
          （二）非经小葱APP或其他权利人书面同意任何人不得擅自使用、修改、复制、公开传播、改变、散布、发行或公开发表本网站程序或内容。
        </p>

        <p>
          （三）尊重知识产权是您应尽的义务，如有违反，您应对小葱APP承担损害赔偿责任。
        </p>

        <h6 class="text-main text-bold margin-top-15 text-size-16">
          八、服务条款的解释、法律适用及争端解决
        </h6>
        <p>（一）小葱APP对本服务条款拥有最终的解释权。</p>

        <p>
          （二）本协议及其修订本的有效性、履行和与本协议及其修订本效力有关的所有事宜，将受中华人民共和国法律管辖，任何争议仅适用中华人民共和国法律。
        </p>

        <p>
          （三）因本协议所引起的用户与小葱APP的任何纠纷或争议，首先应友好协商解决，协商不成的，用户在此完全同意将纠纷或争议提交小葱APP所在地有管辖权的人民法院诉讼解决。
        </p>
        <p>百色市远兰小额贷款有限责任公司</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PrivacyClause",
  components: {},
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
.page-privacy-clause {
  .privacy-con {
    padding: 50px 50px;
    .title {
      h6 {
        font-size: 36px;
        text-align: center;
      }
    }
    .content {
      line-height: 2.6;
    }
  }
}
.text-main {
  color: #597ef7;
}
.text-bold {
  font-weight: bold;
}
.margin-top-15 {
  margin-top: 15px;
}
.text-size-16 {
  font-size: 18px;
}
</style>
